import { Component } from "react";
import QRCode from "react-qr-code";
import { connect } from "react-redux";
import { hideModal } from "../../redux/actions/modalActions";
import { getSharableURL, shareURL } from "../../utils/listFunctions";
import GrocerizeLoadingWheel from "../grocerize/GrocerizeLoadingWheel";
import { Icon } from "../icons/Icon";

interface Props {
  hideModal: any;
  type?:
    | "share_list"
    | "push_to_coles"
    | "push_to_woolworths"
    | "chrome_extension";
  basket: BasketState;
}

interface State {
  id: string;
  body_html: any;
  button_text: string;
  public_url?: string;
}

class SharingPopup extends Component<Props, State> {
  state = {
    id: "",
    body_html: "",
    button_text: "",
    public_url: "",
  };

  addToClipboard = () => {
    var copyText =
      process.env.REACT_APP_FRONTEND_URL + "/lists/" + this.state.public_url;

    navigator.clipboard.writeText(copyText);
  };

  shareUrl = async () => {
    shareURL(this.state.public_url, "Here's my grocerize list");
  };

  downloadGrocerizeExtension = () => {
    const grocerizeExtensionId = process.env.REACT_APP_GROCERIZE_EXT_ID;

    if (!grocerizeExtensionId) {
      console.error("Grocerize Extension ID is not defined.");
      return;
    }
    const grocerizeExtensionUrl = `https://chrome.google.com/webstore/detail/grocerize-push-to-cart/${grocerizeExtensionId}`;

    window.open(grocerizeExtensionUrl, "_blank", "noopener,noreferrer");
  };

  downloadGoggleChrome = () => {
    const chromeUrl =
      "https://www.google.com/chrome/?brand=YTUH&gclid=EAIaIQobChMIhsv1-_Ca-QIVSQVgCh3mpgRpEAAYASAAEgJN__D_BwE&gclsrc=aw.ds";
    window.open(chromeUrl, "_blank", "noopener,noreferrer");
  };
  //   INDEX's: Coles - 1, Wools - 2
  generatePushToCartHtml = (index: number) => {
    const content = [
      {
        index: 0,
        site: "Coles",
        image: "../../../popups/push_to_coles.svg",
        imageAlt: "Push the cart to the coles store",
      },
      {
        index: 1,
        site: "Woolworths",
        image: "../../../popups/push_to_woolworths.svg",
        imageAlt: "Push the cart to the woolworths store",
      },
    ];
    return (
      <div>
        <div
          className="u-main-font--medium u-bold"
          style={{ fontSize: "20px" }}
        >
          Pushing to {content[index].site} cart
        </div>
        <div className="u-pt--24">
          <img
            style={{ maxHeight: 80, minWidth: 200, maxWidth: 300 }}
            src={content[index].image}
            alt={content[index].imageAlt}
          />
        </div>
        <div
          style={{
            fontSize: "14px",
          }}
          className="u-main-font--small u-color-charcoal   u-background--white u-br--top--small"
        >
          Grocerize will push all the items in your {content[index].site} list
          to your {content[index].site} cart via the Grocerize chrome extension.
          <br />
          <br />
          {content[index].site} will open in a new tab and you’ll be prompted to
          login. Items will not push to cart until you are logged in.
          <br />
          <br />
          Happy shopping!
        </div>
      </div>
    );
  };

  componentDidMount = async () => {
    const isChrome =
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    this.state.public_url = await getSharableURL();
    console.log(this.state.public_url);
    const popups = [
      {
        id: "share_list",
        body_html: (
          <div>
            <div
              className="u-main-font--medium u-bold"
              style={{ fontSize: "20px" }}
            >
              Scan to access mobile shopping list
            </div>
            <div
              className="u-pt--8"
              style={{ fontSize: "14px", lineHeight: "18px" }}
            >
              Scan this QR code to access mobile friendly shopping list
              (available for 24 hours).
            </div>
            <div className="u-flex u-justify-center u-w-all u-pv--24">
              <button onClick={this.addToClipboard}>
                <QRCode
                  value={
                    process.env.REACT_APP_FRONTEND_URL +
                    "/lists/" +
                    this.state.public_url
                  }
                />
              </button>
            </div>
            <div className="u-hide--desktop">
              <button
                className="u-background--magenta u-br--small"
                onClick={this.shareUrl}
              >
                <div className="u-color-white u-bold u-ph--24 u-pv--12">
                  Or share
                </div>
              </button>
            </div>
          </div>
        ),
        button_text: "Done",
      },
      {
        id: "push_to_coles",
        body_html: this.generatePushToCartHtml(0),
        button_text: "Done",
        // ID required for chrome extension. NOTE: Changing this will require an update to the chrom extension.
      },
      {
        id: "push_to_woolworths",
        body_html: this.generatePushToCartHtml(1),
        button_text: "Done",
        // ID required for chrome extension. NOTE: Changing this will require an update to the chrom extension.
      },
      {
        id: "chrome_extension",
        body_html: (
          <div>
            <img
              className="u-absolute u-top--0 u-left--0"
              style={{
                maxHeight: 50,
                minWidth: 200,
                maxWidth: 300,
                marginLeft: "-40px",
                marginTop: "20px",
              }}
              src="../../../popups/alert.svg"
              alt="Google chrome and/or the grocerize extension need to be installed"
            />
            <div
              className="u-main-font--medium u-bold u-mt--48 u-mb--24"
              style={{ fontSize: "20px" }}
            >
              Pushing to Coles and Woolworths cart requires
              {isChrome
                ? " the Grocerize Chrome extension"
                : " both Google Chrome and the Grocerize Chrome extension"}
            </div>
            {isChrome ? (
              <></>
            ) : (
              <a
                href="https://www.google.com/intl/en_uk/chrome/"
                target="_blank"
                rel="noreferrer"
              >
                <button
                  className="u-background--magenta u-br--small u-bold  u-main-font--medium u-color-white u-mt--24"
                  style={{ width: "400px", height: "92px" }}
                  onClick={() => this.downloadGoggleChrome()}
                >
                  Download Google Chrome
                </button>
              </a>
            )}
            <button
              className="u-background--magenta u-br--small u-bold  u-main-font--medium u-color-white u-mt--24"
              style={{ width: "400px", height: "92px" }}
              onClick={() => this.downloadGrocerizeExtension()}
            >
              Download Grocerize Chrome Extension
            </button>
          </div>
        ),
        button_text: "Close",
      },
    ];

    let popupDefinition;
    popupDefinition =
      popups.find((item) => item.id === this.props.type) || popups[0];

    this.setState(popupDefinition);
  };

  render() {
    if (this.state.body_html !== "") {
      return (
        <div className="o-modal__container">
          <div className="u-flex u-br--small o-modal--content-centered">
            <div>
              <div
                className="u-main-font--small u-color-charcoal u-background--white u-pv--48 u-text-center u-br--topleft--small"
                style={{
                  paddingLeft: "75px",
                  paddingRight: "75px",
                  fontSize: "14px",
                  width: "550px",
                }}
              >
                {this.state.body_html}
              </div>
              <button
                className="u-main-font--medium u-w-all u-background--green u-pv--20 u-bold u-color-white u-br--bottomleft--small "
                onClick={() => this.props.hideModal()}
              >
                {this.state.button_text}
              </button>
            </div>
            <div
              className="u-hide--mobile u-background--magenta u-color-white u-p--32 u-text-center u-br--right--small"
              style={{ width: "240px" }}
            >
              <Icon className="u-pt--20" id="celebrate" size="xxxl" />
              <div
                className="u-main-font--medium u-bold u-pv--8"
                style={{ fontSize: "20px" }}
              >
                Congrats!
              </div>
              <div className="u-main-font--vsmall" style={{ fontSize: "14px" }}>
                You just saved up to
              </div>
              <div
                className="u-main-font--medium u-bold u-pt--8"
                style={{ fontSize: "20px" }}
              >
                $
                {this.props.basket.basketMetaData.totalSavingsUsingGrocerize.toFixed(
                  2
                )}
                !
              </div>
              {/* socials */}
              {/* {this.props.basket.basketMetaData.totalSavingsUsingGrocerize >
              10 ? (
                <div style={{ paddingTop: 80 }}>
                  <SimpleSharingButton type="instagram" />
                  <SimpleSharingButton type="facebook" />
                </div>
              ) : null} */}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="o-modal__container o-modal u-w-all">
          <div className="o-modal__content u-flex u-justify-center u-align-center u-h-all u-w-all">
            <GrocerizeLoadingWheel />
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state: ReduxState) => ({
  basket: state.basket,
});
export default connect(mapStateToProps, { hideModal })(SharingPopup);

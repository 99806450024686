export const routes = {
  landing: "/landing",
  default: "/",
  browse: "/browse",
  browseWithCategories: "/browse/:categories+",
  my: "/my/:content",
  myFavourites: "/my/favourites",
  myAlerts: "/my/alerts",
  myLists: "/my/lists",
  myDetails: "/my/details",
  grocerize: "/my/grocerize",
  preferences: "/my/preferences",
  signup: "/signup",
  signupForm: "/signup-form",
  faqs: "/faqs",
  premium: "/premium",
  termsOfUse: "/terms-of-use",
  SignUpTermsOfUse: "/sign-up-terms-of-use",
  privacyPolicy: "/privacy-policy",
  contact: "/contact",
  recipes: "/recipes",
  recipe: "/recipe/:id",
  publicLists: "/lists/:id",
  verifyEmail: "/verify-email",
  welcome: "/welcome",
  mobileUserGuide: "/grocerize-user-guide-mobile",
  notFound: "/404",
};

import { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Icon } from "../../src/components/icons/Icon";
import { showPaymentPortal } from "../clients/payments/stripe";
import LoginPrompt from "../components/popups/StandardPopup";
import { showModal } from "../redux/actions/modalActions";
import { resetCss } from "../utils/modal";
import PageTemplate from "./PageTemplate";
import { captureDataOnSignUp } from "../clients/analytics/mixpanel";
import { trackAuthentication } from "../clients/analytics/cliquemarket";
import PremiumFeatures from "../components/features/PremiumFeatures";
import UpcomingFeatures from "../components/features/UpcomingFeatures";

interface Props {
  user: UserState;
  showModal: any;
}

interface State {
  loadingStripe: boolean;
}

class PremiumPage extends Component<Props, State> {
  state = {
    loadingStripe: false,
  };

  componentDidMount(): void {
    resetCss();
  }

  callToAction = () => {
    return (
      <div className="u-flex u-justify-center u-pb--12">
        <button
          className="u-mauto u-pv--32 u-ph--42 u-background--white u-color-magenta  u-main-font--large u-bold-black u-br--small"
          onClick={() => this.clickedToUpgrade()}
        >
          Try free for 1 month
        </button>
      </div>
    );
  };

  clickedToUpgrade = () => {
    if (!this.props.user?.id) {
      this.props.showModal({
        position: "centered",
        component: () => <LoginPrompt type={"alerts"} />,
      });
    } else if (!this.props.user?.isPremium) {
      showPaymentPortal(this.props.user.stripe_id, "")
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    }
  };

  render() {
    if (this.props.user.isPremium) {
      return <Redirect to="/browse" />;
    }

    return (
      <div>
        <PageTemplate
          sidebarType="none"
          hideItemSearching={true}
          headerType="grey"
        >
          <div className="u-flex--desktop u-w-all u-h-all u-relative">
            <div className="u-background--grocerize u-color-white u-w-all u-text-center u-pv--120 u-w-35pct--desktop u-overflow-auto">
              <div className="u-grid u-place--items--center u-h-all">
                <Icon id="grocerize_premium_white" width={243} height={61} />
                <div
                  style={{ lineHeight: 1.4 }}
                  className="u-main-font--22--medium u-bold u-mauto u-pt--32 u-pb--48 u-ph--34"
                >
                  Take your grocery shopping to the next level.
                </div>

                {this.callToAction()}
                <div className="u-mauto u-pt--24 u-font--14">
                  Free for 1 month, then A$2.95 per month after.
                </div>

                <div className="u-bold u-mauto u-pt--24 u-font--14 u-pb--32--mobile">
                  Cancel Anytime
                </div>
              </div>
            </div>
            <div className="u-background--white u-w-all u-grid u-place--items--center--desktop u-h-all u-overflow-auto u-w-65pct--desktop">
              <div className="u-flex--desktop u-p--60--desktop">
                <div className="u-flex-1--desktop">
                  <PremiumFeatures />
                </div>
                <div className="u-flex-1--desktop">
                  <UpcomingFeatures />
                </div>
              </div>
            </div>
          </div>
        </PageTemplate>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
});

export default connect(mapStateToProps, { showModal })(PremiumPage);

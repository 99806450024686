import { Component } from "react";

interface Props {
  title: string;
  description: string;
}

class Feature extends Component<Props> {
  render() {
    const { title, description } = this.props;

    return (
      <div className="u-pb--20">
        <div className="u-main-font--small u-bold">{title}</div>
        <div className="u-color-charcoal u-font--14">{description}</div>
      </div>
    );
  }
}

export default Feature;

import {updateURLSlugs} from "../../components/naviagtions/itemNavs/itemNavigationFunctions";

interface recipeAction {
    type: "GET_RECIPES" | "SELECT_RECIPE"; // ADD OTHERS HERE
    recipesData: RecipeData[];
    itemsData: ItemData[];
    selectedRecipe?: RecipeData | null;
}

const initialState: RecipesState = {
    recipesData: [],
    itemsData: [],
};

export default function recipeReducer(
    state = initialState,
    action: recipeAction
) {
    switch (action.type) {
        case "GET_RECIPES":
            return {
                ...state,
                recipesData: action.recipesData,
            };
        case "SELECT_RECIPE":
            // change the url to reflect the recipe we are in
            // TODO: update urls
//            updateURLSlugs(action.selectedRecipe, state.selectedRecipe);
            return {
                ...state,
                selectedRecipe: action.selectedRecipe,
                itemsData: action.itemsData
            };
        default:
            return state;
    }
}
import React, { Component } from "react";

import { connect } from "react-redux";
import { track } from "../../clients/analytics/mixpanel";

import { trackCheckout } from "../../clients/analytics/cliquemarket";
import { showModal } from "../../redux/actions/modalActions";
import ColesAlertPopup from "../popups/ColesAlertPopup";
import SharingPopup from "../popups/SharingPopup";
import LoginPrompt from "../popups/StandardPopup";
import GrocerizeSortItemsButton from "./GrocerizeSortItemsButton";
import UnlockPremiumModal from "../popups/UnlockPremiumModal";

interface Props {
  basket?: BasketState;
  user?: UserState;
  showModal: any;
  colesNotification: ColesNotificationState;
}

class UseYourListFunctions extends Component<Props, {}> {
  share = (event: string) => {
    // Send to mixpanel now user is likely to have completed journey
    if (!process.env.REACT_APP_DEV_MODE) {
      let metaData = this.props.basket?.basketMetaData;
      track(event, {
        userId: this.props.user?.id,
        grocerised_data: {
          grocerize_total: metaData?.grocerizeTotal || 0,
          total_item_count:
            (metaData?.countOfColesItems || 0) +
            (metaData?.countOfWoolsItems || 0),
          coles_total: metaData?.colesOnlyTotal || 0,
          coles_item_count: metaData?.countOfColesItems || 0,
          woolworths_total: metaData?.woolworthsOnlyTotal || 0,
          woolworths_item_count: metaData?.countOfWoolsItems || 0,
          optimised_price: metaData?.isOptimisedPrice || 0,
          total_savings: metaData?.totalSavingsUsingGrocerize || 0,
        },
      });
    }
  };

  showSharableList = async () => {
    if (!this.props.user?.id) {
      this.showPopup("subscribe");
    } else if (this.props.user?.isPremium) {
      let component = <SharingPopup type="share_list" />;
      if (!this.props.user?.id) {
        component = <LoginPrompt type="cart" />;
      } else {
        this.share("List shared");

        if (this.props.basket && !process.env.REACT_APP_DEV_MODE) {
          trackCheckout("checkout", this.props.basket, "share");
        }
      }

      this.props.showModal({
        position: "centered",
        component: () => component,
      });
    } else {
      this.showPopup("subscribe");
    }
  };

  showPopup = (type: "login" | "subscribe") => {
    const modalContent = () => <UnlockPremiumModal />;

    this.props.showModal({ component: modalContent });
  };

  showPushToCartPopup = async (
    type: "push_to_woolworths" | "chrome_extension"
  ) => {
    if (this.props.user?.isPremium) {
      const modalContent = () => <SharingPopup type={type} />;
      const versionNumber =
        document.getElementById("ChromeExtVersion")?.textContent;

      if (!versionNumber) {
        type = "chrome_extension";
      }

      if (this.props.basket && !process.env.REACT_APP_DEV_MODE) {
        trackCheckout("checkout", this.props.basket, "woolworths");
      }
      this.props.showModal({ position: "centered", component: modalContent });
    } else {
      this.showPopup("subscribe");
    }
  };

  notifyUser = async () => {
    if (!this.props.user?.id) {
      this.showPopup("subscribe");
    } else if (this.props.user?.isPremium) {
      const colesAlertModal = () => <ColesAlertPopup />;
      this.props.showModal({
        position: "centered",
        component: colesAlertModal,
      });
    } else {
      this.showPopup("subscribe");
    }
  };

  getStringifiedBasketList = () => {
    let pushToCartList = [];
    const basketItems = this.props.basket?.basketItems;
    if (basketItems) {
      for (let i = 0; i < basketItems.length; i++) {
        const item = basketItems[i];
        let selectedVendorId, vendorProductCode;
        // Check if item has both pricing options
        if (item.item_pricing.length > 1) {
          // Check if item is available at both stores
          if (
            item.item_pricing[0].available &&
            item.item_pricing[1].available
          ) {
            // Add item to pushToCartList
            selectedVendorId = item.selected_vendor_id;
            // Get the vendor product code of the selected vendor id from item pricings
            if (item.selected_vendor_id === item.item_pricing[0].vendor_id) {
              vendorProductCode = item.item_pricing[0].vendor_product_code;
            } else if (
              item.selected_vendor_id === item.item_pricing[1].vendor_id
            ) {
              vendorProductCode = item.item_pricing[1].vendor_product_code;
            }
          }
        } else {
          // Set selected vendor id to the available vendor
          selectedVendorId = item.item_pricing[0].vendor_id;
          vendorProductCode = item.item_pricing[0].vendor_product_code;
        }

        // Add item to push to cart list
        pushToCartList.push({
          // NOTE: vendor_product_code is the actual product code of the corresponding vendor
          vendor_product_code: vendorProductCode,
          quantity: item.quantity,
          selected_vendor: selectedVendorId,
        });
      }
    }

    return JSON.stringify(pushToCartList);
  };

  render() {
    const functions = [
      {
        key: 1,
        name: () => (
          <div className="u-color-white">Create shopping list / Share list</div>
        ),
        func: () => this.showSharableList(),
        background: "u-background--magenta",
        div_id: "PushToGrocerizeButton",
        margin: "",
      },
      {
        key: 2,
        name: () => <div className="u-color-white">Push items to C cart</div>,
        func: () => this.notifyUser(),
        background: "u-background--coles",
        div_id: "ColesNotificationButton",
        margin: "u-mh--4",
      },
      {
        key: 3,
        name: () => <div className="u-color-white">Push items to W cart</div>,
        func: () => this.showPushToCartPopup("push_to_woolworths"),
        background: "u-background--woolworths",
        div_id: "ChromeExtPushToWoolsButton",
        margin: "",
      },
    ];

    return (
      <div className="u-main-font--small u-background--grey u-pb--8 u-flex u-justify-center">
        <div
          className=" u-flex u-justify-between u-align-center u-w-all u-pl--50--desktop"
          style={{
            paddingTop: "30px",
            fontSize: "14px",
            maxWidth: "1300px",
          }}
        >
          <div className="u-pl--20">
            <GrocerizeSortItemsButton />
          </div>
          <div className="u-pr--24--mobile">
            {functions.map((button, index) => {
              return (
                <button
                  id={button.div_id}
                  key={button.key}
                  className={
                    "u-ph--24 u-pv--24 u-br--small u-semi-bold " +
                    button.background +
                    " " +
                    button.margin
                  }
                  onClick={button.func}
                >
                  {button.name()}
                </button>
              );
            })}
          </div>
        </div>
        {/* Hidden div's to hold info pushed to chrome extension for Push To Cart */}

        <div className="u-hide" id="ChromeExtPushToCartItems">
          {this.props.user && this.props.user.isPremium
            ? this.getStringifiedBasketList()
            : []}
        </div>

        <div className="u-hide" id="ChromeExtVersion"></div>
        {/* ------ Hidden div's end ------ */}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  basket: state.basket,
  user: state.user,
  colesNotification: state.colesNotification,
});

export default connect(mapStateToProps, {
  showModal,
})(UseYourListFunctions);

import { Component } from "react";
import Feature from "./Feature";

interface Option {
  id: number;
  title: string;
  description: string;
}
interface State {
  title: string;
  options: Option[];
}

class PremiumFeatures extends Component<{}, State> {
  state = {
    title: "Premium Features",
    options: [
      {
        id: 1,
        title: "Spilt your shop",
        description:
          "Between Coles and Woolies to utilise every weekly special",
      },
      {
        id: 2,
        title: "Push direct",
        description:
          "To Coles and/or Woolworths websites for delivery or collection",
      },
      {
        id: 3,
        title: "Create unlimited lists",
        description:
          "With weekly shops, specific meals and even special occasions",
      },
      {
        id: 4,
        title: "Set price alerts",
        description: "Be notified so you never miss a bargain again",
      },
      {
        id: 5,
        title: "Favourites",
        description: "Check them at a glance, so you can shop even quicker",
      },
      {
        id: 6,
        title: "Fine tune",
        description: "Use 'manual mode' to customise your savings ",
      },
    ],
  };

  render() {
    const { title, options } = this.state;
    return (
      <div className="u-ph--16">
        <div className="u-color-magenta u-bold u-main-font--22--medium u-pv--12">
          {title}
        </div>
        {options.map((option: Option) => {
          return (
            <Feature
              title={option.title}
              description={option.description}
              key={option.id}
            />
          );
        })}
      </div>
    );
  }
}

export default PremiumFeatures;

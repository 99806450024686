import { Component } from "react";
import { Link } from "react-router-dom";
import { httpClient } from "../../clients/auth/addTokenToHTTPRequests";
import PageTemplate from "../../pages/PageTemplate";
import { Icon } from "../icons/Icon";

interface Props {}

interface State {
  name: string;
  email: string;
  contactNumber: number;
  message: string;
  error: string;
  showError: boolean;
  sendingEmail: boolean;
  sentEmail: boolean;
}

class ContactUs extends Component<Props, State> {
  state = {
    name: "",
    email: "",
    contactNumber: 0,
    message: "",
    error: "The email is missing",
    showError: false,
    sendingEmail: false,
    sentEmail: false,
  };

  validateName = () => {
    if (this.state.name.trim().length === 0) {
      this.setState({ error: "Your first name cannot be blank" });
      return false;
    }
    return true;
  };

  validateEmail = () => {
    if (this.state.email.trim().length === 0) {
      this.setState({ error: "Your email cannot be blank" });
      return false;
    }
    return true;
  };

  validateMessage = () => {
    if (this.state.message.trim().length === 0) {
      this.setState({ error: "You must include a message" });
      return false;
    }
    return true;
  };

  submitUserMessage = () => {
    const isValid = this.validateForm();

    if (isValid) {
      const { name, email, contactNumber, message } = this.state;

      httpClient.instance.post("/email", {
        to: ["blake@grocerize.com.au"],
        subject: "Customer message",
        html: `<div>
                <h1>You have a new message from the grocerize contact form!</h1>
                <h3>From:</h3>${name}<br>
                <h3>Email:</h3>${email}<br>
                <h3>Contact:</h3>${contactNumber}<br>
                <h3>Message:</h3>${message}<br>
              </div>`,
      });

      this.setState({ sendingEmail: true });
      setTimeout(
        () => this.setState({ sendingEmail: false, sentEmail: true }),
        700
      );
      console.log("sent");
    }
  };

  validateForm = () => {
    const validation = {
      name: this.validateName(),
      email: this.validateEmail(),
      message: this.validateMessage(),
    };

    // if all checks pass
    if (Object.values(validation).every((field) => field === true)) {
      this.setState({ showError: false });
      return true;
    } else {
      this.setState({ showError: true });
      return false;
    }
  };

  render() {
    const contacts = [
      { type: "All enquiries:", href: "blake@grocerize.com.au" },
    ];
    return (
      <PageTemplate sidebarType="options" hideItemSearching={true}>
        <div className="u-w-all u-background--white">
          <div className="u-flex u-justify-center u-border--bottom--grey">
            <div className="u-p--48--desktop  " style={{ width: "80%" }}>
              <div className="u-main-font--large u-color-charcoal u-bold u-pv--32">
                Contact Us
              </div>
            </div>
          </div>
          <div className="u-flex u-justify-center u-border--bottom--grey">
            <div
              className="u-p--48--desktop  u-p--24--mobile"
              style={{ width: "80%" }}
            >
              <div className="u-main-font--small  u-h-all">
                <div className="u-flex--desktop">
                  {this.state.sentEmail ? (
                    <div className="u-pt--32 u-semi-bold u-w-half--desktop">
                      Thanks!
                      <br />
                      <br />
                      We will respond asap.
                    </div>
                  ) : (
                    <div className=" u-pt--32 u-semi-bold u-w-all">
                      Have a question? Send us a message!
                      <div className="u-mt--24">
                        <input
                          className="o-signup-input"
                          placeholder="Your name"
                          type="text"
                          onChange={(e) =>
                            this.setState({ name: e.currentTarget.value })
                          }
                          onBlur={() => this.validateName()}
                        />
                      </div>
                      <div className="u-mt--12">
                        <input
                          className="o-signup-input"
                          placeholder="Email"
                          type="email"
                          onChange={(e) =>
                            this.setState({ email: e.currentTarget.value })
                          }
                          onBlur={() => this.validateEmail()}
                        />
                      </div>
                      <div className="u-mt--12">
                        <input
                          className="o-signup-input"
                          placeholder="Contact Number"
                          type="number"
                          onChange={(e) =>
                            this.setState({
                              contactNumber: parseFloat(e.currentTarget.value),
                            })
                          }
                        />
                      </div>
                      <div className="u-mt--12">
                        <textarea
                          className="o-signup-input"
                          placeholder="Message"
                          style={{
                            height: "200px",
                          }}
                          onChange={(e) =>
                            this.setState({ message: e.currentTarget.value })
                          }
                          onBlur={() => this.validateMessage()}
                        />
                      </div>
                      <div className="u-mt--12">
                        <div className="u-color-magenta">
                          {this.state.showError ? (
                            <div className="u-pv--12">{this.state.error}</div>
                          ) : (
                            <></>
                          )}
                        </div>

                        <button
                          className="u-w-all u-background--magenta u-pv--20 u-bold u-color-white u-br--small u-flex u-justify-center u-align-center u-text-center"
                          onClick={() => this.submitUserMessage()}
                        >
                          {this.state.sendingEmail ? (
                            <div className="o-loader"></div>
                          ) : (
                            "Send"
                          )}
                        </button>
                      </div>
                    </div>
                  )}
                  <div className="u-pv--34--desktop u-ml--150--desktop u-pb--10--mobile u-w-all">
                    <div className="u-pt--32--mobile u-bold">Social</div>

                    <div className="u-mt--24">
                      <Link
                        to={{ pathname: "https://www.facebook.com/Grocerize/" }}
                        target="_blank"
                      >
                        <Icon id="facebook" size="l" fill="#323232" />
                      </Link>
                      <Link
                        to={{
                          pathname:
                            "https://www.linkedin.com/company/grocerize",
                        }}
                        className="u-ph--32"
                        target="_blank"
                      >
                        <Icon id="linkedin" size="l" fill="#323232" />
                      </Link>
                      <Link
                        to={{
                          pathname:
                            "https://www.instagram.com/grocerize/?hl=en",
                        }}
                        target="_blank"
                      >
                        <Icon id="instagram" size="l" fill="#323232" />
                      </Link>
                    </div>
                    {/* <div className="u-mt--32 ">
                      <div className="u-bold">Points of contact</div>
                      <div className="u-mt--12">
                        {contacts.map((contact) => (
                          <div
                            className="u-flex u-pv--12"
                            style={{ fontSize: 14 }}
                          >
                            <div className="u-w-25pct ">{contact.type}</div>
                            <div
                              className={
                                contact.type === "Support"
                                  ? "u-pl--20--desktop u-pl--12--mobile"
                                  : "u-pl--12"
                              }
                            >
                              <a
                                className="u-color-magenta"
                                href={`mailto:${contact.href}`}
                              >
                                {contact.href}
                              </a>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageTemplate>
    );
  }
}

export default ContactUs;

import axios from "axios";
import { Dispatch } from "react";

export const getRecipesFromDatabase = () => (dispatch: Dispatch<any>) => {
    return axios
        .get(process.env.REACT_APP_BACKEND_URL + `/recipes`)
        .then((res) => {
            dispatch(recipesData(res.data));
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getRecipeItemsFromDatabase = () => (dispatch: Dispatch<any>, idList: number[]) => {
    const idListQ = JSON.stringify(idList);
    if (idList && idList.length > 0)
    {
        return axios
            .get(process.env.REACT_APP_BACKEND_URL + `/search/idlist?q=${idListQ}`)
            .then((res) => {
                dispatch(recipesItemData(res.data));
            })
            .catch((err) => {
                console.log(err);
            });
    }
    return [];
}


const recipesItemData = (recipeItems: ItemData[]) => {
    return {
        type: "SELECT_RECIPE",
        itemsData: recipeItems,
    };
};

const recipesData = (data: RecipeData[]) => {
    return {
        type: "GET_RECIPES",
        recipesData: data,
    };
};

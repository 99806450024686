import {Component} from "react";
import {connect} from "react-redux";
import {getRecipesFromDatabase} from "../../redux/actions/recipeActions";
import RecipeBanner from "../../components/recipes/RecipeBanner";
import RecipesGrid from "../../components/recipes/RecipesGrid";


interface Props {
    recipes: RecipesState;
    getRecipesFromDatabase: any;
}

interface State {
    isLoading: boolean;
    recipesToDisplay: RecipeData[];
}

class RecipesView extends Component<Props, State> {
    state = {
        isLoading: false,
        recipesToDisplay: [],
    };
    componentDidMount = async () => {
        this.props.getRecipesFromDatabase();
    };

    componentDidUpdate = (prevProps: Props) => {
    }


    render() {

        return (
                <>
                    <div className="u-ph--16 u-w-all u-hide--mobile u-flex--desktop u-align-center u-justify-between u-h--75px--desktop">
                        <RecipeBanner recipeTitle="Recipes" />
                    </div>
                    <RecipesGrid getRecipesToDisplay={this.props.getRecipesFromDatabase} />
                </>
        );
    }
}

const mapStateToProps = (state: ReduxState) => ({
    recipes: state.recipes
});

export default connect(mapStateToProps, {
        getRecipesFromDatabase,
    })(RecipesView);

import { withAuth0 } from "@auth0/auth0-react";
import { Component } from "react";
import { connect } from "react-redux";

import { changeVendorToBuyFrom } from "../../redux/actions/basketActions";
import { showModal } from "../../redux/actions/modalActions";
import { updateUserGrocerizeAutoModePreference } from "../../redux/actions/userActions";
import { COLES, GROCERIZE, WOOLWORTHS } from "../../redux/enums";
import StandardPopup from "../popups/StandardPopup";
import GrocerizeVendorPricing from "./GrocerizeVendorPricing";

interface Props {
  auth0: any;
  user: UserState;
  showModal: any;
  itemData: ItemData;
  changeVendorToBuyFrom: any;
  woolworthsPrice?: number;
  colesPrice?: number;
  woolsPricing?: ItemPricings | null;
  colesPricing?: ItemPricings | null;
  updateUserGrocerizeAutoModePreference: any;
  updatedMetaData: BasketMetaDataState;
}

interface State {
  selectedVendor: number;
}

class GrocerizeItemTogglePrices extends Component<Props, State> {
  state = {
    selectedVendor: GROCERIZE,
  };
  componentDidMount = () => {
    this.setToggle();
  };

  componentDidUpdate = (prevProps: Props) => {
    // if the selected vendor changes update state
    if (
      this.props.itemData !== prevProps.itemData ||
      this.props.colesPrice !== prevProps.colesPrice ||
      this.props.woolworthsPrice !== prevProps.woolworthsPrice
    ) {
      this.setToggle();
    }
  };

  setToggle = () => {
    let selectedVendor;
    // determine if there is a selected price already
    if (this.props.itemData.selected_vendor_id) {
      selectedVendor = this.props.itemData.selected_vendor_id;
    } else {
      // else find the cheapest or with most items on basket
      selectedVendor =
        (this.props.woolworthsPrice || 1000) > (this.props.colesPrice || 1000)
          ? COLES
          : WOOLWORTHS;

      if (this.props.woolworthsPrice === this.props.colesPrice) {
        selectedVendor =
          this.props.updatedMetaData.initialCountOfColesItems >
          this.props.updatedMetaData.initialCountOfWoolsItems
            ? COLES
            : WOOLWORTHS;
      }
    }

    this.setState({ selectedVendor });
  };

  clickedToggle = () => {
    // if a price in "n/a" do not do anything
    if (
      typeof this.props.colesPrice !== "number" ||
      typeof this.props.woolworthsPrice !== "number"
    ) {
      return;
    }
    // the toggle should only toggle if there is an available price to go to
    else if (this.props.itemData.selected_vendor_id === COLES) {
      if (
        this.props.woolworthsPrice !== 0 &&
        this.props.woolsPricing?.available
      ) {
        this.props.changeVendorToBuyFrom(this.props.itemData, WOOLWORTHS);
        this.props.updateUserGrocerizeAutoModePreference(false);
      }
    } else {
      if (this.props.colesPrice !== 0 && this.props.colesPricing?.available) {
        this.props.changeVendorToBuyFrom(this.props.itemData, COLES);
        this.props.updateUserGrocerizeAutoModePreference(false);
      }
    }
  };

  render() {
    const woolworthsPricing = this.props.woolsPricing;
    const colesPricing = this.props.colesPricing;
    let tempSelectedVendor = this.state.selectedVendor;

    if (
      this.state.selectedVendor === COLES &&
      !this.props.colesPricing?.available
    ) {
      tempSelectedVendor = WOOLWORTHS;
    } else if (
      this.state.selectedVendor === WOOLWORTHS &&
      !this.props.woolsPricing?.available
    ) {
      tempSelectedVendor = COLES;
    }

    return (
      <div
        className="u-w-all--mobile u-flex u-align-center"
        style={{ zIndex: 0 }}
      >
        <GrocerizeVendorPricing
          vendorId={1}
          isToggled={tempSelectedVendor === COLES}
          pricing={colesPricing ? colesPricing : null}
          onClickHandler={this.clickedToggle}
        />
        <button
          id="grocerize-toggle-button"
          className={
            "ninth-tour u-background--white u-br--all u-flex u-align-center " +
            (this.state.selectedVendor === COLES &&
            this.props.colesPricing?.available
              ? " u-border--coles"
              : " u-border--woolworths")
          }
          style={{
            width: "60px",
            height: "37px",
            marginLeft: "-26px",
            marginRight: "-26px",
            zIndex: 2,
          }}
          onClick={() => this.clickedToggle()}
        >
          <div className="u-w-all u-ph--6">
            <div
              className={
                "u-br--all" +
                (this.state.selectedVendor === COLES &&
                this.props.colesPricing?.available
                  ? " u-background--coles"
                  : " u-background--woolworths u-float--right")
              }
              style={{
                width: "25px",
                height: "25px",
                zIndex: 2,
              }}
            ></div>
          </div>
        </button>
        <GrocerizeVendorPricing
          vendorId={2}
          isToggled={tempSelectedVendor === WOOLWORTHS}
          pricing={woolworthsPricing ? woolworthsPricing : null}
          onClickHandler={this.clickedToggle}
        />
      </div>
    );
  }
}
const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
  updatedMetaData: state.basket.basketMetaData,
});

export default withAuth0(
  connect(mapStateToProps, {
    changeVendorToBuyFrom,
    updateUserGrocerizeAutoModePreference,
    showModal,
  })(GrocerizeItemTogglePrices)
);

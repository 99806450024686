import { Component } from "react";
import { connect } from "react-redux";
import { showPaymentPortal } from "../../clients/payments/stripe";
import { showModal, hideModal } from "../../redux/actions/modalActions";
import { Icon } from "../icons/Icon";
import PremiumFeatures from "../features/PremiumFeatures";
import UpcomingFeatures from "../features/UpcomingFeatures";
import { withAuth0 } from "@auth0/auth0-react";

interface Props {
  user: UserState;
  showModal: any;
  hideModal: any;
  auth0: any;
}

interface State {
  loadingStripe: boolean;
}

class UnlockPremiumModal extends Component<Props, State> {
  state = {
    loadingStripe: false,
  };

  clickedToUpgrade = () => {
    if (!this.props.auth0.isAuthenticated) {
      this.showSignUp();
    }
    // show a spinner
    this.setState({ loadingStripe: true });

    if (this.props.user.stripe_id) {
      showPaymentPortal(this.props.user.stripe_id, "").catch((err) => {
        console.log(err);
      });
    }
  };

  showSignUp = () => {
    this.props.auth0.loginWithRedirect({
      login_hint: "signup",
      redirect_uri: "/signup",
    });
  };

  callToAction = () => {
    return (
      <div className="u-flex u-justify-center u-pb--12">
        <button
          className="u-mauto u-pv--32 u-ph--42 u-background--white u-color-magenta  u-main-font--large u-bold-black u-br--small"
          onClick={() => this.clickedToUpgrade()}
        >
          {!this.props.user.isPremium || !this.props?.user.auth0_id
            ? "Try free for 1 month"
            : "Upgrade Account Now"}
        </button>
      </div>
    );
  };

  render() {
    if (window.innerWidth < 600) {
      return (
        <div className="o-modal__container ">
          <div className="u-w-all u-br--medium u-color-white u-text-center o-modal--content-bottom u-background--grocerize">
            <div
              className="u-flex u-justify-center"
              style={{ paddingTop: "70px", paddingBottom: "38px" }}
            >
              <Icon id="grocerize_premium_white" width={243} height={61} />
            </div>
            <div
              style={{ width: 282, lineHeight: 1.2 }}
              className="u-main-font--medium u-bold u-mauto u-pt--32 u-pb--48"
            >
              Take your grocery shopping to the next level.
            </div>
            {!this.state.loadingStripe && this.callToAction()}
            <div className="u-mauto u-pv--24 u-main-font--vsmall">
              Free for 1 month, then A$2.95 per month after.
            </div>

            <div className="u-bold u-mauto u-pt--24 u-font--14 u-pb--32--mobile">
              Cancel Anytime
            </div>

            {this.state.loadingStripe && (
              <div className="u-flex u-justify-center">
                {this.state.loadingStripe && (
                  <div className=" u-pv--20 u-ph--20  u-br--small u-background--white u-color-magenta u-flex u-justify-center">
                    <div className="o-loader"></div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      );
    }
    return (
      <div className="u-br--small o-modal__container">
        <div
          className=" o-modal--content-centered "
          style={{
            width: "90%",
            height: "80%",
          }}
        >
          <button
            className="u-absolute"
            style={{ top: 18, right: 18, zIndex: 2 }}
            onClick={() => this.props.hideModal()}
          >
            <Icon id="clear" stroke="grey" size="s" />
          </button>

          <div className="u-flex u-w-all u-h-all u-relative">
            <div className="u-background--grocerize u-color-white u-w-all u-text-center u-pv--120 u-w-35pct  u-overflow-auto">
              <div className="u-grid u-place--items--center u-h-all u-ph--12">
                <Icon id="grocerize_premium_white" width={243} height={61} />
                <div
                  style={{ lineHeight: 1.4 }}
                  className="u-main-font--22--medium u-bold u-mauto u-pt--32 u-pb--48 u-ph--34"
                >
                  Take your grocery shopping to the next level.
                </div>

                {!this.state.loadingStripe && this.callToAction()}
                <div className="u-mauto u-pt--24 u-font--14">
                  Free for 1 month, then A$2.95 per month after.
                </div>
                <div className="u-bold u-mauto u-pt--24 u-font--14">
                  Cancel Anytime
                </div>
                {this.state.loadingStripe && (
                  <div className="u-flex u-justify-center">
                    {this.state.loadingStripe && (
                      <div className=" u-pv--20 u-ph--20  u-br--small u-background--white u-color-magenta u-flex u-justify-center">
                        <div className="o-loader"></div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="u-background--white u-w-all u-grid u-place--items--center u-h-all u-overflow-auto u-w-65pct">
              <div className="u-flex u-p--60">
                <div className="u-flex-1">
                  <PremiumFeatures />
                </div>
                <div className="u-flex-1 u-ml--35">
                  <UpcomingFeatures />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
});

export default withAuth0(
  connect(mapStateToProps, { showModal, hideModal })(UnlockPremiumModal)
);

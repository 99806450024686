import { Component } from "react";
import { connect } from "react-redux";

import { withAuth0 } from "@auth0/auth0-react";
import { track } from "../clients/analytics/mixpanel";
import GrocerizeLoadingWheel from "../components/grocerize/GrocerizeLoadingWheel";

import moment from "moment";
import { trackAuthentication } from "../clients/analytics/cliquemarket";
import Checkbox from "../components/checkbox/Checkbox";
import ItemsGrid from "../components/items/ItemsGrid";
import FiltersDropdownButton from "../components/naviagtions/itemNavs/FiltersDropdownButton";
import SearchTermsBanner from "../components/naviagtions/itemNavs/SearchTermsBanner";
import SearchTitleBanner from "../components/naviagtions/itemNavs/SearchTitleBanner";
import SortAndFilterDropdown from "../components/naviagtions/itemNavs/SortAndFilterDropdown";
import SortDropdownButton from "../components/naviagtions/itemNavs/SortDropdownButton";
import PriceUpdatePopup from "../components/popups/PriceUpdatePopup";
import UserGuidePopup from "../components/popups/UserGuidePopup";
import {
  getCategoryTreeFromDB,
  saveNewCategoryFilterSelection,
} from "../redux/actions/categoryAction";
import { getUserFavourites } from "../redux/actions/favouritesActions";
import { toggleUnavailableProducts } from "../redux/actions/itemActions";
import { hideModal, showModal } from "../redux/actions/modalActions";
import { getUserPriceAlerts } from "../redux/actions/priceAlertActions";
import { saveNewSearch } from "../redux/actions/searchActions";
import {
  checkUserStatus,
  deleteUserFromAuth0,
} from "../redux/actions/userActions";
import { createClone } from "../utils/UIFunctions";
import { routes } from "../utils/routes";
import { searchHasValue } from "../utils/searchFunctions";
import PageTemplate from "./PageTemplate";
import { Link } from "react-router-dom";

interface GrocerizeExpectedURLSearchParams extends URLSearchParams {
  s?: string; // search terms
}

interface Props {
  categories: CategoryState;
  saveNewCategoryFilterSelection: any;
  getCategoryTreeFromDB: any;
  saveNewSearch: any;
  auth0: any;
  search: SearchState;
  checkUserStatus: any;
  deleteUserFromAuth0: any;
  user: UserState;
  toggleUnavailableProducts: (isUnavailableProductsHidden: boolean) => void;
  isUnavailableProductsHidden?: boolean;
  showModal: any;
}

interface State {
  isLoading: boolean;
}

class ItemsHome extends Component<Props, State> {
  state = {
    isLoading: false,
    isUnavailableProductsHidden: this.props.isUnavailableProductsHidden,
  };
  componentDidMount = async () => {
    if (!process.env.REACT_APP_DEV_MODE) {
      track("page_load--browse_items");
    }
    // on page load get params
    this.getURLFilterAndSearchParams();

    if (!this.hasVisitedWebsite()) {
      const modalContent = () => <UserGuidePopup />;
      this.props.showModal({ component: modalContent });
    }

    const now = moment().local();

    if (now.day() === 3 && now.hours() < 11) {
      // revise the logic later on
      // const modalPriceUpdated = () => <PriceUpdatePopup />;
      // this.props.showModal({ component: modalPriceUpdated });
    }

    console.log(this.freeTrialGetEnd(new Date(), false));
  };

  freeTrialGetEnd(trialStartedAt: Date, isPartnerTrial: boolean) {
    const defaultTrialLength = 30;
    const partnerTrialLength = 90;
    return Math.floor(
      new Date(
        new Date(trialStartedAt).setDate(
          new Date(trialStartedAt).getDate() +
            (isPartnerTrial ? partnerTrialLength : defaultTrialLength)
        )
      ).getTime() / 1000
    );
  }

  componentDidUpdate = (prevProps: Props) => {
    if (
      prevProps.categories.categoryTree !== this.props.categories.categoryTree
    ) {
      this.getURLFilterAndSearchParams();
    }

    if (prevProps.user !== this.props.user) {
      if (this.props.user.id && !process.env.REACT_APP_DEV_MODE) {
        trackAuthentication(
          {
            id: this.props.user.id,
            email: this.props.user.email,
            prefereredStore: this.props.user.shopping_preference,
            source: this.props.user.auth0_id,
            postcode: this.props.user.postcode,
          },
          "login"
        );
      }
    }
  };

  hasVisitedWebsite = () => {
    return localStorage.getItem("has-visited-website") === null ? false : true;
  };

  reloadPage = () => {
    <div className="o-modal">
      <div className="o-modal__background "></div>
      <div className="o-modal__container o-modal u-w-all">
        <div className="o-modal__content u-flex u-justify-center u-align-center u-h-all u-w-all">
          <GrocerizeLoadingWheel />
        </div>
      </div>
    </div>;
  };

  getURLFilterAndSearchParams = () => {
    this.getURLSearchParam();
    this.getURLFilterParams();
  };

  getURLFilterParams = () => {
    // do not clear search url param when search has value
    if (searchHasValue(this.props.search)) {
      return null;
    }
    // If the url has categories in it
    if (window.location.pathname.toLowerCase().includes("/browse/")) {
      // get the url slugs that are mentioned into an array
      const filtersAndSearchURLString = window.location.pathname
        .toLowerCase()
        .split("/browse/")[1];
      const filtersURLString = filtersAndSearchURLString.split("?")[0];
      const categoriesArray = filtersURLString.split("/");

      // find the node that we should be on
      const selectedNode = this.getTreeNodeFromUrlSlugs(categoriesArray);

      if (selectedNode) {
        // if found set the filter
        this.props.saveNewCategoryFilterSelection(selectedNode);
      } else {
        if (window.location.pathname.toLowerCase().includes("/browse")) {
          if (window.location.search.length) {
            // else find the search term
            const queryParams = new URLSearchParams(window.location.search);
            const getCatUrlSlug = queryParams.get("cat");

            if (getCatUrlSlug) {
              window.history.pushState(
                {},
                "",
                routes.browse + "/" + getCatUrlSlug
              );
            } else {
              // if not reset
              this.props.saveNewCategoryFilterSelection(null);
            }
          }
        } else {
          // if not reset
          this.props.saveNewCategoryFilterSelection(null);
        }
      }
    } else {
      // else clear any current filters
      this.props.saveNewCategoryFilterSelection(null);
    }
  };

  getTreeNodeFromUrlSlugs = (slugs: string[]) => {
    // inital conditions should be the top of the tree
    let parentNode = null;

    if (!this.props.categories?.categoryTree) {
      this.props.getCategoryTreeFromDB();

      return null;
    }

    let childrenNodes: CategoryTreeNode[] = createClone(
      this.props.categories.categoryTree
    );

    // go through the tree, capturing the found node at each step
    for (let i = 0; i < slugs.length; i++) {
      // if the slug is empty use the previous (this can happen when /browse/baby/ gets split)
      if (slugs[i] === "") {
        break;
      }

      // find the node with that url_slug
      const node = childrenNodes.find((node) => {
        return node.url_slug === slugs[i];
      });

      // if we find a matching node
      if (node) {
        // set the parent node in case we are going to nav into it
        parentNode = node;
        //if there are children cache them too
        if (node?.children) {
          childrenNodes = node.children;
        }
      } else {
        // if we couldn't find the node break the loop
        // TODO: we should also trim the url so that if they type /browse/baby/sdadsa it becomes /browse/baby
        break;
      }
    }

    return parentNode;
  };

  getURLSearchParam = () => {
    if (window.location.pathname.toLowerCase().includes("/browse")) {
      if (window.location.search.length) {
        // else find the search term
        const params: GrocerizeExpectedURLSearchParams = new URLSearchParams(
          window.location.search
        );
        const searchTermFromUrl = params.get("s");

        if (searchTermFromUrl) {
          this.props.saveNewSearch(searchTermFromUrl);
        }
      } else {
        // clear the search history
        this.props.saveNewSearch("");
      }
    }
  };

  toggleUnavailableProducts = () => {
    this.props.toggleUnavailableProducts(
      !this.props.isUnavailableProductsHidden
    );
  };

  storeUserInteraction = () => {
    localStorage.setItem("has-visited-website", "yes");
  };

  render() {
    if (this.state.isLoading) {
      this.reloadPage();
    }
    const isUnavailableProductsHidden =
      this.props.isUnavailableProductsHidden || false;

    return (
      <PageTemplate sidebarType="items">
        <>
          {/*<Link to="/recipes">*/}
          {/*  <div*/}
          {/*    className="u-w-all u-h-116px--desktop"*/}
          {/*    style={{*/}
          {/*      backgroundImage: "url(/images/bbau_ripple_banner.jpg)",*/}
          {/*      maxWidth: "100%",*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <div className="u-w-all u-h-all u-flex u-justify-left u-align-center u-text-center">*/}
          {/*      <div>*/}
          {/*        <img*/}
          {/*          src="/images/bba-logo.png"*/}
          {/*          alt="Feed your family for under $15"*/}
          {/*          style={{ width: "50%" }}*/}
          {/*        />*/}
          {/*      </div>*/}
          {/*      <div className="u-w-all u-h-all u-flex u-justify-center u-align-center u-text-center">*/}
          {/*        <div className="u-bold u-color-white u-ph--20 u-pv--8 u-bg--magenta u-br--small u-text-oneline--mobile u-main-font--banner--desktop">*/}
          {/*          Family Feeds for $15*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</Link>*/}
          <div className="u-ph--16 u-w-all u-hide--mobile u-flex--desktop u-align-center u-justify-between u-h--75px--desktop">
            <SearchTitleBanner />
            <div className="u-flex--desktop u-pr--50--desktop u-align-center">
              <Checkbox
                label="Hide Unavailable Products"
                isChecked={isUnavailableProductsHidden}
                action={this.toggleUnavailableProducts}
              />
              <FiltersDropdownButton />
              <SortDropdownButton />
            </div>
          </div>
          <div className="u-hide--desktop u-ph--8">
            <SortAndFilterDropdown />
          </div>
          <div className="u-background--grey u-hide--desktop u-p--16">
            <SearchTitleBanner />
          </div>
          {this.props.search.allSearchTerms.length ? (
            <SearchTermsBanner />
          ) : null}
          <ItemsGrid />
        </>
      </PageTemplate>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  categories: state.categories,
  search: state.search,
  isUnavailableProductsHidden: state.items.isUnavailableProductsHidden,
});

export default withAuth0(
  connect(mapStateToProps, {
    saveNewCategoryFilterSelection,
    getCategoryTreeFromDB,
    saveNewSearch,
    getUserFavourites,
    getUserPriceAlerts,
    checkUserStatus,
    deleteUserFromAuth0,
    toggleUnavailableProducts,
    showModal,
    hideModal,
  })(ItemsHome)
);

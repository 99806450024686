import { Component } from "react";
import Feature from "./Feature";

interface Option {
  id: number;
  title: string;
  description: string;
}
interface State {
  title: string;
  options: Option[];
}

class UpcomingFeatures extends Component<{}, State> {
  state = {
    title: "Upcoming Features",
    options: [
      {
        id: 1,
        title: "AI Recipe Generation",
        description:
          "Augmented reality fridge scanning  creates meal recipes, adding missing ingredients automatically",
      },
      {
        id: 2,
        title: "Purchase Prediction",
        description:
          "Anticipates your' future needs and recommends items you regularly buy when on special",
      },
      {
        id: 3,
        title: "AI Pantry Checker",
        description:
          "Scans your pantry for products that you usually buy and builds predictive lists, which are added to your list automatically",
      },
      // {
      //   id: 4,
      //   title: "Set price alerts",
      //   description: "Be notified so you never miss a bargain again",
      // },
      {
        id: 5,
        title: "Weekly Planner",
        description:
          "Visual and voice combined with AI and user data to generate complete weekly meals on a user-defined budget",
      },
    ],
  };

  render() {
    const { title, options } = this.state;
    return (
      <div className="u-ph--16">
        <div className="u-color-magenta u-bold u-main-font--22--medium u-pv--12">
          {title}
        </div>
        {options.map((option: Option) => {
          return (
            <Feature
              title={option.title}
              description={option.description}
              key={option.id}
            />
          );
        })}
      </div>
    );
  }
}

export default UpcomingFeatures;

import {Component} from "react";
import GrocerizeLoadingWheel from "../../components/grocerize/GrocerizeLoadingWheel";
import {connect} from "react-redux";
import {getRecipesFromDatabase} from "../../redux/actions/recipeActions";
import RecipeBanner from "../recipes/RecipeBanner";

interface Props {
    recipes: RecipesState;
    getRecipesFromDatabase: any;
}

interface State {
    isLoading: boolean;
    recipesToDisplay: RecipeData[];
    currentRecipeId: string;
    imageLoadHasFailed: boolean;
}

class Recipe extends Component<Props, State> {
    state = {
        isLoading: false,
        recipesToDisplay: [],
        currentRecipeId: window.location.pathname
            .toLowerCase()
            .split("/recipe/")[1],
        imageLoadHasFailed: false
    };
    componentDidMount = async () => {
        this.props.getRecipesFromDatabase();
    };

    componentDidUpdate = (prevProps: Props) => {

    };

    render() {
        if (this.state.isLoading && this.props.recipes.recipesData) {
            return <GrocerizeLoadingWheel />;
        }
        const currentRecipe = this.props.recipes.recipesData.find(x => x.id === parseInt(this.state.currentRecipeId));
        return (
            <>
                { currentRecipe && <div>
                    <RecipeBanner recipeTitle={currentRecipe?.title} />
                    {currentRecipe?.id ? (
                        <img
                            id="item-image"
                            style={{height: "140px"}}
                            src={`/images/recipes/${currentRecipe.id}.jpg`}
                            alt={currentRecipe.title}
                            onError={() => {
                                this.setState({imageLoadHasFailed: true});
                            }}
                        />
                    ) : (
                        <div
                            className="u-background--grey u-w-all  u-main-font--vsmall u-semi-bold u-text-center"
                            style={{height: "140px", width: "140px"}}
                        >
                            No image found.
                        </div>
                    )}
                    <div>{currentRecipe.title}</div>
                    <div>{currentRecipe.ingredientsText}</div>
                    <div>{currentRecipe.summary}</div>
                    <div>{currentRecipe.steps}</div>
                </div>}
            </>
        );
    }
}

const mapStateToProps = (state: ReduxState) => ({
    recipes: state.recipes
});

export default connect(mapStateToProps, {
        getRecipesFromDatabase,
    })(Recipe);

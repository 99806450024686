import { Component } from "react";
import { connect } from "react-redux";
import { showModal } from "../../redux/actions/modalActions";
import { getSharableURL } from "../../utils/listFunctions";
import LoginPrompt from "../popups/StandardPopup";
import GrocerizeShareListModalMobile from "./GrocerizeShareListModalMobile";
import UnlockPremiumModal from "../popups/UnlockPremiumModal";

interface Props {
  showModal: any;
  user?: UserState;
  basket?: BasketState;
}

class GrocerizeBasketMobileFooter extends Component<Props, {}> {
  propertiesToTrack = (vendor?: "coles" | "woolworths") => {
    const metaData = this.props.basket?.basketMetaData;
    return {
      totalSaved: metaData?.totalSavingsUsingGrocerize || 0,
      pushed: vendor,
      cart: {
        items:
          (metaData?.countOfColesItems || 0) +
          (metaData?.countOfWoolsItems || 0),
        coles: {
          items: metaData?.countOfColesItems || 0,
          value: metaData?.colesOnlyTotal || 0,
        },
        woolworths: {
          items: metaData?.countOfWoolsItems || 0,
          value: metaData?.woolworthsOnlyTotal || 0,
        },
      },
    };
  };

  showSharableList = async () => {
    const public_url = await getSharableURL();

    if (typeof public_url === "string") {
      let modalContent = () => (
        <GrocerizeShareListModalMobile listUrlAddress={public_url} />
      );

      if (!this.props.user?.id) {
        modalContent = () => <LoginPrompt type="cart" />;
      }

      if (this.props.user.isPremium === false) {
        modalContent = () => <UnlockPremiumModal />;
      }

      this.props.showModal({ position: "centered", component: modalContent });
    }
  };

  render() {
    return (
      <div
        className=" u-background--secondary u-flex u-align-center u-hide--desktop"
        style={{ height: "66px", width: "100vw" }} // vw hack to force it to fill enter screen
      >
        {/* @TODO is this used for user who are not logged in? */}
        {/* <button
          className="u-ph--24 u-semi-bold u-h-all u-align-center u-flex u-justify-center u-main-font--small  u-color-white"
          onClick={this.saveList}
        >
          <div className="u-text-oneline" style={{ fontSize: "16px" }}>
            Save list
          </div>
        </button> */}

        <button
          className="u-w-all u-flex u-justify-center u-align-center u-background--green u-h-all"
          onClick={this.showSharableList}
        >
          <div
            className="u-main-font--small u-semi-bold u-color-white u-ph--8"
            style={{ fontSize: "16px" }}
          >
            Create shopping list
          </div>
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
  basket: state.basket,
});

export default connect(mapStateToProps, { showModal })(
  GrocerizeBasketMobileFooter
);

import { Component } from "react";

import GrocerizeBasketItemCard from "../components/grocerize/GrocerizeBasketItemCard";
import GrocerizeBasketMobileFooter from "../components/grocerize/GrocerizeBasketMobileFooter";
import GrocerizeListTotalSummary from "../components/grocerize/GrocerizeListTotalSummary";
import GrocerizeOverviewDisplay from "../components/grocerize/GrocerizeOverviewDisplay";
import UseYourListFunctions from "../components/grocerize/UseYourListFunctions";
import PageTemplate from "./PageTemplate";

import { connect } from "react-redux";
import { track } from "../clients/analytics/mixpanel";
import GrocerizeSortItemsButton from "../components/grocerize/GrocerizeSortItemsButton";
import { Link } from "react-router-dom";

interface Props {
  basket?: BasketState;
}

class GrocerizeHome extends Component<Props, {}> {
  componentDidMount = () => {
    if (!process.env.REACT_APP_DEV_MODE) {
      track("page_load--grocerize_page");
    }
  };

  render() {
    return (
      <PageTemplate
        sidebarType="none"
        hideItemSearching={window.innerWidth < 600} // hide on mobile to hide corner grey square
        isCategoryClick={true}
      >
        <GrocerizeOverviewDisplay />
        <GrocerizeListTotalSummary />

        {window.innerWidth > 600 ? (
          <UseYourListFunctions />
        ) : (
          <GrocerizeSortItemsButton />
        )}

        {this.props.basket?.basketItems?.length > 0 && (
          <>
            <div className="u-background--white u-w-all">
              {this.props.basket?.basketItems.map((item) => {
                return (
                  <GrocerizeBasketItemCard
                    itemData={item}
                    key={JSON.stringify(item)} // stringify this data to be a uniwue to spot redux state changes and hence rerender
                  />
                );
              })}
            </div>

            <GrocerizeBasketMobileFooter />
          </>
        )}
      </PageTemplate>
    );
  }
}
const mapStateToProps = (state: ReduxState) => ({
  basket: state.basket,
});

export default connect(mapStateToProps, null)(GrocerizeHome);

import { withAuth0 } from "@auth0/auth0-react";
import { Component } from "react";
import { connect } from "react-redux";
import {
  addToUserFavourite,
  deleteUserFavourite,
} from "../../../../redux/actions/favouritesActions";
import { showModal } from "../../../../redux/actions/modalActions";
import { Icon } from "../../../icons/Icon";
import LoginPrompt from "../../../popups/StandardPopup";
import UnlockPremiumModal from "../../../popups/UnlockPremiumModal";

interface Props {
  item_id: number;
  auth0: any;
  user: UserState;
  deleteUserFavourite: any;
  addToUserFavourite: any;
  showModal: any;
  favourites: FavouritesState;
}

interface State {
  isFavourite: boolean;
}

class FavouriteIndicator extends Component<Props, State> {
  state = {
    isFavourite: false,
  };

  componentDidMount = () => {
    // check if is user fav
    this.setState({ isFavourite: this.isItemUsersFav(this.props.item_id) });
  };

  componentDidUpdate = (prevProps: Props) => {
    if (this.props.favourites.favourites) {
      if (
        this.props.favourites.favourites !== prevProps.favourites.favourites
      ) {
        this.setState({
          isFavourite: this.isItemUsersFav(this.props.item_id),
        });
      }
    }
  };

  isItemUsersFav = (item_id: number) => {
    let isFavourite = false;
    if (this.props.favourites.favourites) {
      this.props.favourites.favourites.forEach((fav: Favourites) => {
        if (fav.item.id === item_id) {
          isFavourite = true;
        }
      });
    }

    return isFavourite;
  };

  clickedFavouriteIndicator = async () => {
    // if logged in
    if (this.props.auth0.isAuthenticated) {
      if (this.props.user.isPremium) {
        if (!this.state.isFavourite) {
          this.setState({ isFavourite: true });
          await this.props.addToUserFavourite(this.props.item_id);
        } else {
          // delete the fav
          await this.props.deleteUserFavourite(this.props.item_id);
        }
      } else {
        this.showPopup("subscribe");
      }
    } else {
      // show the popup modal
      this.showPopup("subscribe");
    }
  };

  showPopup = (type: "login" | "subscribe") => {
    let modalContent;
    if (type === "subscribe") {
      modalContent = () => <UnlockPremiumModal />;
    } else {
      modalContent = () => <LoginPrompt type={"favourites"} />;
    }
    this.props.showModal({ component: modalContent });
  };

  render() {
    return (
      <button
        className={this.state.isFavourite ? "u-background--magenta" : ""}
        style={{
          width: "40px",
          height: "40px",
          paddingRight: "10px",
          borderRadius: "0 8px 0 80% ",
          textAlign: "right",
        }}
        onClick={() => this.clickedFavouriteIndicator()}
      >
        <Icon
          id="heart"
          size="s"
          fill={this.state.isFavourite ? "#fff" : "#eeeeee"}
        />
      </button>
    );
  }
}
const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
  favourites: state.favourites,
});
export default withAuth0<any>(
  connect(mapStateToProps, {
    deleteUserFavourite,
    addToUserFavourite,
    showModal,
  })(FavouriteIndicator)
);
